import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import CircularProBold from './fonts/CircularPro-Bold.woff2';
import CircularProBoldItalic from './fonts/CircularPro-BoldItalic.woff2';
import CircularProBook from './fonts/CircularPro-Book.woff2';
import CircularProBookItalic from './fonts/CircularPro-BookItalic.woff2';
import CircularProMedium from './fonts/CircularPro-Medium.woff2';
import CircularProMediumItalic from './fonts/CircularPro-MediumItalic.woff2';
import RobotoMonoRegular from './fonts/RobotoMono-Regular.woff2';
const theme = createTheme({
    palette: {
        primary: {
            main: '#43a047',
            light: '#b9f6ca',
            dark: '#8AD07F',
            contrastText: '#00360f',
        },
        secondary: {
            main: '#00360F',
            light: '#b9f6ca',
            dark: '#43a047',
            contrastText: '#fff',
        },
        success: {
            main: '#b9f6ca',
            light: '#b9f6ca',
            dark: '#43a047',
            contrastText: '#00360f',
        },
        error: {
            main: '#FF3D00',
        },
        error95: {
            main: '#FFEDE9',
            contrastText: '#DD2C00',
        },
        background: {
            default: '#fafafa',
        },
        info: {
            main: '#757575',
        },
    },
    typography: {
        fontFamily: 'CircularProBook',
        fontSize: 16,
        h1: {
            color: '#16A100',
            fontSize: '20px',
            paddingLeft: '20px',
        },
        h3: {
            fontWeight: 700,
            fontSize: '20px',
            color: grey[900],
            paddingBottom: '16px',
        },
        h4: {
            fontWeight: 500,
            fontSize: '16px',
            color: grey[500],
        },
        h5: {
            fontWeight: 500,
        },
        h6: {
            fontWeight: 500,
            fontSize: '20px',
        },
    },
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '8px',
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#EEEEEE',
                    color: grey[600],
                    lineHeight: '24px',
                    fontSize: '14px',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '16px',
                    color: grey[900],
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: '48px',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: '64px',
                    paddingLeft: '20px !important',
                    paddingRight: '20px !important',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                icon: {
                    alignItems: 'center',
                    color: `${grey[900]} !important`,
                },
                root: {
                    backgroundColor: 'transparent',
                    zIndex: 2,
                    color: grey[900],
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#b9f6ca',
                    boxShadow: 'none',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    ':last-child': {
                        paddingBottom: '16px',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    display: 'block',
                    position: 'relative',
                },
                action: {
                    color: grey[600],
                    position: 'absolute',
                    right: '16px',
                    height: '100%',
                    top: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    margin: 0,
                },
                title: {
                    fontSize: '14px',
                    color: grey[600],
                },
                subheader: {
                    fontSize: '14px',
                    color: grey[900],
                    maxWidth: '480px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '16px',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    flexWrap: 'wrap-reverse',
                    padding: '4px 24px 16px 24px',
                    '& > .MuiButton-root': {
                        marginBottom: '8px',
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    border: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        borderRadius: 20,
                        borderColor: '#E0E0E0',
                        paddingLeft: 24,
                        paddingRight: 24,
                    },
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        borderRadius: 20,
                        borderColor: '#E0E0E0',
                        paddingLeft: 24,
                        paddingRight: 24,
                    },
                },
                {
                    props: { variant: 'text' },
                    style: {
                        borderRadius: 25,
                        paddingLeft: 24,
                        paddingRight: 24,
                    },
                },
            ],
        },
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'CircularProBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('CircularProBold'), url(${CircularProBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      
      
        @font-face {
          font-family: 'CircularProBoldItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 700;
          src: local('CircularProBoldItalic'), url(${CircularProBoldItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      
      
        @font-face {
          font-family: 'CircularProBook';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('CircularProBook'), url(${CircularProBook}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      
      
        @font-face {
          font-family: 'CircularProBookItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('CircularProBookItalic'), url(${CircularProBookItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      
      
      @font-face {
        font-family: 'CircularProMedium';
        font-style: normal;
        font-display: swap;
        font-weight: 500;
        src: local('CircularProMedium'), url(${CircularProMedium}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    
    
      @font-face {
        font-family: 'CircularProMediumItalic';
        font-style: italic;
        font-display: swap;
        font-weight: 500;
        src: local('CircularProMediumItalic'), url(${CircularProMediumItalic}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }

      @font-face {
        font-family: 'RobotoMonoRegular';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('RobotoMonoRegular'), url(${RobotoMonoRegular}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    `,
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    top: '58px',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#8AD07F',
                    },
                    borderRadius: '24px',
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    height: '56px',
                    padding: '4px 12px',
                    backgroundColor: '#b9f6ca',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 5,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#00360F',
                    },
                    '&.Mui-selected > span > span': {
                        right: '16px',
                    },
                    '&.Mui-selected > span > span > svg': {
                        width: '12px',
                    },
                    '&.Mui-selected > svg, &.Mui-selected > span > svg': {
                        backgroundColor: '#8AD07F',
                        width: '56px',
                        borderRadius: '12px',
                    },
                    color: '#00360F',
                    flexGrow: 0,
                    minWidth: '80px',
                },
                label: {
                    fontSize: '12px',
                    '&.Mui-selected': {
                        fontSize: '12px',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    top: 'calc(50% - 12px)',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    height: '24px',
                    width: '24px',
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    height: '16px',
                    fontSize: '10px !important',
                },
                dot: {
                    height: '8px',
                },
            },
        },
        LogbookFormGrid: {
            padding: '20px 0px',
        },
        MuiGrid2: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: grey[600],
                    borderWidth: '1px',
                },
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiDateOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderWidth: '0px',
                },
                root: {},
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiDatePicker: {
            minWidth: '130px',
            marginBottom: '16px',
            paddingRight: '12px',
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginLeft: '-16px',
                },
            },
        },
    },
});

export default theme;
