import React from 'react';
import { useNavigate } from 'react-router-dom';

function InfleetSvg() {
    const navigate = useNavigate();
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='161' height='64' viewBox='0 0 161 64' onClick={() => navigate('/')} cursor='pointer'>
            <g id='logo' transform='translate(-28 13.996)'>
                <rect id='Rechteck_245' data-name='Rechteck 245' width='161' height='64' transform='translate(28 -13.996)' fill='none' />
                <g id='Infleet-Logo-RGB' transform='translate(62.895 1.997)'>
                    <path
                        id='Pfad_4068'
                        data-name='Pfad 4068'
                        d='M327.967,129.017c0-5.408-3.078-8.717-8.2-8.717s-8.166,3.487-8.166,8.86a8.659,8.659,0,0,0,8.575,9c4.554,0,6.067-1.886,7.187-4.341l-3.54-1.708a3.693,3.693,0,0,1-3.612,2.153,4.246,4.246,0,0,1-4.145-3.594h11.813a15.954,15.954,0,0,0,.107-1.637Zm-11.831-1.726a3.622,3.622,0,0,1,3.718-3.327,3.5,3.5,0,0,1,3.718,3.327h-7.419Z'
                        transform='translate(-266.038 -113.024)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_4069'
                        data-name='Pfad 4069'
                        d='M429.967,129.017c0-5.408-3.078-8.717-8.2-8.717s-8.166,3.487-8.166,8.86a8.66,8.66,0,0,0,8.575,9c4.554,0,6.067-1.886,7.187-4.341l-3.54-1.708a3.693,3.693,0,0,1-3.612,2.153,4.246,4.246,0,0,1-4.145-3.594h11.813a15.954,15.954,0,0,0,.107-1.637Zm-11.831-1.726a3.622,3.622,0,0,1,3.718-3.327,3.5,3.5,0,0,1,3.718,3.327h-7.419Z'
                        transform='translate(-349.892 -113.024)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_4070'
                        data-name='Pfad 4070'
                        d='M520.865,97.274l.053-3.861h4.91V89.535H520.99l.125-7.935h-4.465L516.4,97.932c0,6.618,3.861,8.379,8.4,8.379a9.74,9.74,0,0,0,1.05-.071v-4.377a9.574,9.574,0,0,1-1.352.107c-2.864,0-3.629-1.832-3.629-4.715'
                        transform='translate(-434.403 -81.209)'
                        fill='#43a047'
                    />
                    <rect id='Rechteck_2715' data-name='Rechteck 2715' width='4.519' height='24.622' transform='translate(39.122 0.498)' fill='#43a047' />
                    <g id='Gruppe_3727' data-name='Gruppe 3727' transform='translate(0 0)'>
                        <rect id='Rechteck_2716' data-name='Rechteck 2716' width='4.537' height='16.794' transform='translate(0.552 8.326)' fill='#43a047' />
                        <path
                            id='Pfad_4071'
                            data-name='Pfad 4071'
                            d='M55.5,82.229a2.793,2.793,0,0,0,5.586,0A2.84,2.84,0,0,0,58.293,79.4,2.794,2.794,0,0,0,55.5,82.229'
                            transform='translate(-55.5 -79.4)'
                            fill='#43a047'
                        />
                    </g>
                    <path
                        id='Pfad_4072'
                        data-name='Pfad 4072'
                        d='M110.419,125.4c-3.754,0-7.419,1.8-7.419,6.921v10.016h4.376V133.21c0-2.473,1.085-3.772,3.024-3.772s3.007,1.3,3.007,3.772v9.127h4.377V132.321c0-5.106-3.629-6.921-7.383-6.921'
                        transform='translate(-94.549 -117.216)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_4073'
                        data-name='Pfad 4073'
                        d='M211.83,89.955h5v3.878H211.99l.125,12.578h-4.465L207.4,90.079c0-6.618,3.861-8.379,8.4-8.379a9.731,9.731,0,0,1,1.05.071v4.376a9.576,9.576,0,0,0-1.352-.107c-2.864,0-3.629,1.832-3.629,4.715'
                        transform='translate(-180.376 -81.291)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_4074'
                        data-name='Pfad 4074'
                        d='M597.028,98.333h-.658V96.821h.658a.77.77,0,1,1,0,1.53m1.139.89a1.785,1.785,0,0,0,1.139-1.655A2.166,2.166,0,0,0,597.3,95.7H595v5.835h1.388V99.489h.391l1.032,2.046h1.53l-1.174-2.277Z'
                        transform='translate(-499.02 -92.8)'
                        fill='#43a047'
                    />
                    <path
                        id='Pfad_4075'
                        data-name='Pfad 4075'
                        d='M580.708,91.525a4.235,4.235,0,1,1,4.145-4.252,4.14,4.14,0,0,1-4.145,4.252m0-9.625a5.373,5.373,0,1,0,5.426,5.373,5.4,5.4,0,0,0-5.426-5.373'
                        transform='translate(-482.824 -81.455)'
                        fill='#43a047'
                    />
                </g>
            </g>
        </svg>
    );
}

export default InfleetSvg;
