import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@mui/icons-material/Apps';
import Badge from '@mui/material/Badge';
import LogbookSelection from '../ui/LogbookSelection';
import { Divider, useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../other/ConfigBasic';
import { useEffect } from 'react';
import { useState } from 'react';
import { useGetLicenses, useUpdateAppSettings } from '../../api/LogbookApi';
import AccountMenu from './AccountMenu';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import InfleetSvg from '../../img/InfleetSvg';
// For further development
// import NotificationsIcon from '@mui/icons-material/Notifications';

function createData(name, license, link, icon, inactiveIcon) {
    return { name, license, link, icon, inactiveIcon };
}

//value of process.env.PUBLIC_URL = /v2 automatic from create React app reverse to the public directory
const cloudApps = [
    createData(
        'Maps',
        'maps',
        process.env.REACT_APP_LINK_MAPS,
        process.env.PUBLIC_URL + '/imgs/apps/mape-karte-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/mape-karte-app-icon.svg'
    ),
    createData(
        'Archive',
        'archive',
        process.env.REACT_APP_LINK_ARCHIVE,
        process.env.PUBLIC_URL + '/imgs/apps/archive-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/archive-app-icon.svg'
    ),
    createData(
        'Reports',
        'reports',
        process.env.REACT_APP_LINK_REPORTS,
        process.env.PUBLIC_URL + '/imgs/apps/reports-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/Reports-App-icon.svg'
    ),
    createData(
        'Alarm',
        'events',
        process.env.REACT_APP_LINK_ALARM,
        process.env.PUBLIC_URL + '/imgs/apps/events-ereignisse-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/events-ereignisse-app-icon.svg'
    ),
    createData(
        'Calendar',
        'dates',
        process.env.REACT_APP_LINK_CALENDAR,
        process.env.PUBLIC_URL + '/imgs/apps/dates-termine-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/dates-termine-app-icon.svg'
    ),
    createData(
        'Tours',
        'tours',
        process.env.REACT_APP_LINK_TOURS,
        process.env.PUBLIC_URL + '/imgs/apps/tours-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/tours-app-Icon.svg'
    ),
    createData(
        'Logbook',
        process.env.REACT_APP_LIZENZ,
        process.env.REACT_APP_LINK_LOGBOOK,
        process.env.PUBLIC_URL + '/imgs/apps/logbook-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/logbook-app-icon.svg'
    ),
    createData(
        'Admin',
        'admin',
        process.env.REACT_APP_LINK_ADMIN,
        process.env.PUBLIC_URL + '/imgs/apps/admin-verwaltung-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/admin-verwaltung-app-icon.svg'
    ),
];

const portalApps = [
    createData(
        'Infleet',
        'infleet',
        process.env.REACT_APP_LINK_INFLEET,
        process.env.PUBLIC_URL + '/imgs/apps/infleet-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/infleet-app-icon.svg'
    ),
    createData(
        'Migardo',
        'migardo',
        process.env.REACT_APP_LINK_MIGARDO,
        process.env.PUBLIC_URL + '/imgs/apps/migardo-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/migardo-app-icon.svg'
    ),
    createData(
        'Work',
        'work',
        process.env.REACT_APP_LINK_WORK,
        process.env.PUBLIC_URL + '/imgs/apps/work-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/work-app-icon.svg'
    ),
    createData(
        'Tacho',
        'tacho',
        process.env.REACT_APP_LINK_TACHO,
        process.env.PUBLIC_URL + '/imgs/apps/tacho-app-icon.svg',
        process.env.PUBLIC_URL + '/imgs/apps/inactive/tacho-app-icon.svg'
    ),
];

function checkLicense(license, permissions) {
    if (permissions) {
        return permissions.filter((p) => p.name === '*' || p.name === license?.toLowerCase()).length > 0;
    }
}

export default function LogBookBar(props) {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [anchorEl, setAnchorEl] = useState(null);
    const [appAnchorEl, setAppAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isAppMenuOpen = Boolean(appAnchorEl);
    const logbookList = props.logbookList;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const permissions = useGetLicenses();
    const [message, setMessage] = useState('');
    const { updateAppSettings } = useUpdateAppSettings(props.loginUser.id, setMessage);

    useEffect(() => {
        setIsDrawerOpen(props.menuOpen);
    }, [props.menuOpen]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAppMenuOpen = (event) => {
        setAppAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAppAnchorEl(null);
    };

    const handleLogbookChange = (logbookId) => {
        if (logbookList.find((logbook) => logbook.value === logbookId) !== undefined) {
            updateAppSettings({ settingId: 'lastLogbook', settingValue: logbookId });
        }
        props.setLogTour(logbookId);
    };

    const customMenuCss = {
        display: 'block',
        width: '314px',
        textAlign: 'left',
        padding: '10px',
    };

    const customMenuItemCss = {
        display: 'inline-block',
    };

    cloudApps.sort((a, b) => {
        if (checkLicense(a.license, permissions) && !checkLicense(b.license, permissions)) {
            return -1;
        } else if (!checkLicense(a.license, permissions) && checkLicense(b.license, permissions)) {
            return 1;
        } else {
            return 0;
        }
    });

    portalApps.sort((a, b) => {
        if (checkLicense(a.license, permissions) && !checkLicense(b.license, permissions)) {
            return -1;
        } else if (!checkLicense(a.license, permissions) && checkLicense(b.license, permissions)) {
            return 1;
        } else {
            return 0;
        }
    });

    const cloudAppItems = (
        <Box sx={customMenuCss}>
            {cloudApps.map((app, index) => {
                return (
                    <MenuItem className='customMenuItem' sx={customMenuItemCss} onClick={handleMenuClose} key={index}>
                        <a className='customMenuItemContent' target='_blank' rel='noreferrer' variant='subtitle1' href={app.link}>
                            <img src={checkLicense(app.license, permissions) ? app.icon : app.inactiveIcon} alt={app.name} loading='lazy' />
                            <Typography variant='caption text' component='p' sx={{ margin: '2px' }}>
                                {app.name}
                            </Typography>
                        </a>
                    </MenuItem>
                );
            })}
        </Box>
    );

    const portalAppItems = (
        <Box sx={customMenuCss}>
            {portalApps.map((app, index) => {
                return (
                    <MenuItem className='customMenuItem' sx={customMenuItemCss} onClick={handleMenuClose} key={index}>
                        <a className='customMenuItemContent' target='_blank' rel='noreferrer' variant='subtitle1' href={app.link}>
                            <img src={checkLicense(app.license, permissions) ? app.icon : app.inactiveIcon} alt={app.name} loading='lazy' />
                            <Typography variant='caption text' component='p' sx={{ margin: '2px' }}>
                                {app.name}
                            </Typography>
                        </a>
                    </MenuItem>
                );
            })}
        </Box>
    );

    const appId = 'primary-app-menu';
    const renderAppMenu = (
        <Menu
            sx={{
                '& .MuiPaper-root': {
                    width: 300,
                    maxWidth: 300,
                    maxHeight: '464px',
                },
            }}
            anchorEl={appAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={appId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isAppMenuOpen}
            onClose={handleMenuClose}
        >
            {cloudAppItems}
            <Divider />
            {portalAppItems}
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position='fixed'
                sx={{
                    paddingLeft: isDrawerOpen && desktop900 ? '250px' : '0px',
                }}
            >
                <Toolbar>
                    {!desktop900 && (
                        <IconButton size='medium' edge='start' color='inherit' aria-label='open drawer' sx={{ margin: 'auto' }} onClick={props.updateMenuState}>
                            {props.showHint?.settings ? (
                                <Badge color='error' variant='dot'>
                                    <MenuIcon />
                                </Badge>
                            ) : (
                                <MenuIcon />
                            )}
                        </IconButton>
                    )}
                    {desktop900 && !isDrawerOpen && (
                        <IconButton size='medium' edge='start' color='inherit' aria-label='open drawer' sx={{ margin: 'auto' }} onClick={props.updateMenuState}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <LogbookSelection
                        logbookList={logbookList.filter((logbook) => logbook.loginUserAccess !== null)}
                        handleLogbookChange={handleLogbookChange}
                        logbookId={props.logbookId}
                        loginUser={props.loginUser}
                        lastLogbookId={props.lastLogbookId}
                    />
                    {desktop900 && (
                        <Typography
                            component='div'
                            sx={{
                                height: '64px',
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            <InfleetSvg />
                        </Typography>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex' }}>
                        {desktop900 ? (
                            <IconButton size='large' edge='end' aria-label='Borneamnn Apps' aria-controls={appId} aria-haspopup='true' onClick={handleAppMenuOpen} color='inherit'>
                                <AppsIcon />
                            </IconButton>
                        ) : (
                            <></>
                            // For further development
                            // <IconButton size='large' edge='end' aria-label='Notifications' aria-haspopup='false' color='inherit'>
                            //     <NotificationsIcon />
                            // </IconButton>
                        )}
                        <AccountMenu
                            anchorEl={anchorEl}
                            isMenuOpen={isMenuOpen}
                            handleProfileMenuOpen={handleProfileMenuOpen}
                            handleMenuClose={handleMenuClose}
                            loginUser={props.loginUser}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            {renderAppMenu}
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </Box>
    );
}
