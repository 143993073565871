import * as React from 'react';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import { useContactIsDriverApi, useGetLogbookDriversApi } from '../../api/LogbookApi';
import { useTranslation } from 'react-i18next';
import { searchUserAsDriver, getFullname, GetTourTypes } from '../other/ConfigBasic';
import { Box } from '@mui/material';
import SimpleSnackbar from '../ui/SimpleSnackbar';

export default function MarkTourAsTemplate(props) {
    const { t } = useTranslation();
    const { currentLogbook, saveTours, confirmButtonText = t('confirm') } = props;
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState('');
    const loginUser = useOutletContext().loginUser;
    let allDriverData = useContactIsDriverApi(setMessage);
    let driverIds = useGetLogbookDriversApi(currentLogbook);
    const showIcon = props.showIcon;

    if (!allDriverData || !driverIds) {
        return <LinearProgress />;
    }
    let logbookDriverData = allDriverData.filter((driver) => driverIds.includes(driver.id));

    if (selectedDriver.length === 0) {
        searchUserAsDriver(loginUser.loginUuid, logbookDriverData, setSelectedDriver, false);
    }

    const handleDriverChange = (event) => {
        setSelectedDriver(event.target.value);
    };

    const handleSave = () => {
        setOpen(false);
        saveTours(selectedDriver, props.tourType, props.successMessage);
    };

    return (
        <>
            {showIcon && (
                <Box
                    sx={{
                        alignSelf: 'center',
                        marginRight: '24px',
                        display: 'flex',
                    }}
                    onClick={() => setOpen(true)}
                >
                    {props.icon}
                </Box>
            )}
            {!showIcon && (
                <Button variant='text' color='primary' onClick={() => setOpen(true)}>
                    {props.buttonText}
                </Button>
            )}
            <Dialog
                open={open}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
            >
                <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{t('whoDrove')}</DialogTitle>
                <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                        <InputLabel id='demo-simple-select-label'>{t('selectDriver')}</InputLabel>
                        <Select labelId='demo-simple-select-label' id='demo-simple-select' value={selectedDriver} label={t('selectDriver')} onChange={handleDriverChange}>
                            {logbookDriverData.map((driver) => (
                                <MenuItem key={driver.id} value={getFullname(driver)}>
                                    {getFullname(driver)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {props.tourType === GetTourTypes().PRIVATE && <DialogContentText sx={{ paddingTop: '8px' }}>{t('bannerPrivateTour')}</DialogContentText>}
                </DialogContent>
                <DialogActions sx={{ minHeight: '88px', padding: '24px' }}>
                    <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important' }} color='secondary' onClick={() => setOpen(false)}>
                        {t('cancel')}
                    </Button>
                    {selectedDriver && (
                        <Button
                            variant='contained'
                            sx={{ height: '40px', marginBottom: '0px !important' }}
                            color={props.tourType === GetTourTypes().PRIVATE ? 'error95' : 'success'}
                            onClick={() => handleSave()}
                        >
                            {confirmButtonText}
                        </Button>
                    )}
                    {!selectedDriver && (
                        <Button
                            variant='contained'
                            sx={{ height: '40px', marginBottom: '0px !important' }}
                            color={props.tourType === GetTourTypes().PRIVATE ? 'error95' : 'success'}
                            disabled
                        >
                            {confirmButtonText}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}
