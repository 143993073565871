import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { GetNotificationTopics } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';

export default function NotificationTopic(props) {
    const { userTopic, extend, onChange, disabled, notificationEmails, id, setOpenReceivers, setPrivateReveivers, checked } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        if(!checked?.includes(id)) {
            setSelectedTopic(null);
        }
    }, [checked, id])

    useEffect(() => {
        const handleUserTopic = (userId) => {
            let topics = new Set();
            notificationEmails?.forEach((entry) => {
                entry.notifications.forEach((notification) => {
                    if (notification.receivers.includes(userId)) {
                        topics.add(notification.topic);
                    }
                });
            });

            return topics.size > 1 ? 'all' : topics.size === 1 ? topics.values().next().value : null;
        }

        if (userTopic) {
            if (userTopic === 'all') {
                setOpenReceivers(prevOpenReceivers => [...new Set([...prevOpenReceivers, id])]);
                setPrivateReveivers(prevPrivateReveivers => [...new Set([...prevPrivateReveivers, id])]);
            } else if (userTopic === 'openTours') {
                setOpenReceivers(prevOpenReceivers => [...new Set([...prevOpenReceivers, id])]);
            } else if (userTopic === 'privateNotify') {
                setPrivateReveivers(prevPrivateReveivers => [...new Set([...prevPrivateReveivers, id])]);
            }
            setSelectedTopic(userTopic);
        } else if (notificationEmails?.length > 0 && id) {
            const topic = handleUserTopic(id);
            setSelectedTopic(topic);
            if (topic === 'all') {
                setOpenReceivers(prevOpenReceivers => [...new Set([...prevOpenReceivers, id])]);
                setPrivateReveivers(prevPrivateReveivers => [...new Set([...prevPrivateReveivers, id])]);
            } else if (topic === 'openTours') {
                setOpenReceivers(prevOpenReceivers => [...new Set([...prevOpenReceivers, id])]);
            } else if (topic === 'privateNotify') {
                setPrivateReveivers(prevPrivateReveivers => [...new Set([...prevPrivateReveivers, id])]);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTopic, id, notificationEmails]);

    const handleButtonClick = (event) => {
        setAnchorEl(null);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (topic) => {
        setAnchorEl(null);
        setSelectedTopic(topic);
        if (onChange) {
            onChange(topic);
        }
        if (userTopic === null) {
            // If the topic is "all", update both openToursReceived and privateTopicReceived
            if (topic === 'all') {
                setOpenReceivers(prevOpenReceivers => [...new Set([...prevOpenReceivers, id])]);
                setPrivateReveivers(prevPrivateReveivers => [...new Set([...prevPrivateReveivers, id])]);
            } else if (topic === 'openTours') {
                setOpenReceivers(prevOpenReceivers => {
                    const updatedReceivers = prevOpenReceivers.filter(receiverId => receiverId !== id);
                    return [...new Set([...updatedReceivers, id])];
                });
                setPrivateReveivers(prevPrivateReveivers => prevPrivateReveivers.filter(receiverId => receiverId !== id));
            } else if (topic === 'privateNotify') {
                setPrivateReveivers(prevPrivateReveivers => {
                    const updatedReceivers = prevPrivateReveivers.filter(receiverId => receiverId !== id);
                    return [...new Set([...updatedReceivers, id])];
                });
                setOpenReceivers(prevOpenReceivers => prevOpenReceivers.filter(receiverId => receiverId !== id));
            }
        }
    };

    const topicName = (topic) => {
        return topic ? t(`${topic}`) : t('selectTopic');
    };

    return (
        <div>
            <Button
                sx={{ fontSize: '14px', width: '220px', p: 0.5 }}
                variant='outlined'
                color='primary'
                id='lock-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleButtonClick}
                disabled={disabled}
            >
                {topicName(selectedTopic)}
            </Button>
            <Menu
                id='lock-menu'
                sx={{ fontSize: '14px' }}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {extend
                    ? ['all', 'openTours', 'privateNotify'].map((topic) => {
                        return (
                            <MenuItem key={topic} value={topic} onClick={() => handleMenuItemClick(topic)} sx={{ fontSize: '14px' }}>
                                {topic ? t(`${topic}`) : 'differantTopics'}
                            </MenuItem>
                        );
                    })
                    : Object.values(GetNotificationTopics()).map((topic) => (
                        <MenuItem key={topic} value={topic} onClick={() => handleMenuItemClick(topic)} sx={{ fontSize: '14px' }} disabled={topic === selectedTopic}>
                            {t(`${topic}`)}
                        </MenuItem>
                    ))}
            </Menu>
        </div>
    );
}
