import { Button, InputBase, LinearProgress, Stack } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../themes/theme';

import AddIcon from '@mui/icons-material/Add';
import AccessDialog from './AccessDialog';
import { useGetPersonsApi } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router-dom';
const SearchAndAddAccess = (props) => {
    const { t, searchTerm, setSearchTerm, userSelected, setUserSelected, updateUserData, teamAccess, setTeamAccess, userData } = props;
    const [open, setOpen] = useState(false);
    const currentUser = useOutletContext().loginUser
    const newPersonsData = useGetPersonsApi();
    if (!newPersonsData) {
        return <LinearProgress />;
    }

    const filteredUsers = newPersonsData
        .filter((user) => {
            if (user.zluser && user.zluser.permissions) {
                return user.zluser.permissions.some((permission) => {
                    return permission.name === '*' || permission.name === process.env.REACT_APP_LIZENZ;
                });
            }
            return false;
        })
        .map((user) => ({
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            access: [],
        }))
        .filter((user) => user.id !== currentUser.id);

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack
                direction={'row'}
                alignItems='center'
                justifyContent='space-between'
                padding='6px'
                sx={{ backgroundColor: theme.palette.grey[100] }}
                borderRadius='4px'
                marginBottom='8px'
                flexBasis='30%'
            >
                <InputBase placeholder={t('searchInList')} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} sx={{ fontSize: '14px' }} />
            </Stack>

            <Stack >
                <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                    {t('add')}
                </Button>

                <AccessDialog
                    userSelected={userSelected}
                    setUserSelected={setUserSelected}
                    open={open}
                    setOpen={setOpen}
                    title={t('access')}
                    userList={filteredUsers}
                    t={t}
                    updateUserData={updateUserData}
                    teamAccess={teamAccess}
                    setTeamAccess={setTeamAccess}
                    userData={userData}
                />
            </Stack>
        </Stack>
    );
};

export default SearchAndAddAccess;
