import * as React from 'react';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import { useContactIsDriverApi, useGetLogbookDriversApi } from '../../api/LogbookApi';
import { useTranslation } from 'react-i18next';
import { searchUserAsDriver, getFullname } from '../other/ConfigBasic';
import SimpleSnackbar from '../ui/SimpleSnackbar';

export default function MergeToursDialog(props) {
    const { mergeTours, handleClose, minDtBegin, maxDtEnd } = props;
    const [message, setMessage] = useState('');
    const [selectedDriver, setSelectedDriver] = useState('');
    const currentLogbook = useOutletContext().logbookId;
    const { t } = useTranslation();
    const loginUser = useOutletContext().loginUser;
    let allDriverData = useContactIsDriverApi(setMessage);
    let driverIds = useGetLogbookDriversApi(currentLogbook);

    if (!allDriverData || !driverIds) {
        return <LinearProgress />;
    }
    let logbookDriverData = allDriverData.filter((driver) => driverIds.includes(driver.id));

    if (selectedDriver.length === 0) {
        searchUserAsDriver(loginUser.loginUuid, logbookDriverData, setSelectedDriver, false);
    }

    const handleDriverChange = (event) => {
        setSelectedDriver(event.target.value);
    };

    const handleMerge = () => {
        handleClose(false);
        mergeTours({
            idLogbook: currentLogbook,
            dtBegin: minDtBegin,
            dtEnd: maxDtEnd,
            driverFullName: selectedDriver,
        })
    };

    return (
        <>
            <DialogTitle sx={{padding: '24px 24px 16px 24px'}}>{t('markAsPrivatTour')}?</DialogTitle>
            <DialogContent sx={{padding:'0px 24px 0px 24px'}}>
                <DialogContentText sx={{ paddingBottom: '16px' }}>{t('mergeToursAsPrivate')}</DialogContentText>
                <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>{t('selectDriver')}</InputLabel>
                    <Select labelId='demo-simple-select-label' id='demo-simple-select' value={selectedDriver} label={t('selectDriver')} onChange={handleDriverChange}>
                        {logbookDriverData.map((driver) => (
                            <MenuItem key={driver.id} value={getFullname(driver)}>
                                {getFullname(driver)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ minHeight: '88px',padding: '24px'}}>
                <Button variant='text' sx={{ height: '40px', marginBottom: '0px !important'}} color='secondary' onClick={() => handleClose(false)}>
                    {t('cancel')}
                </Button>
                {selectedDriver && (
                    <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important' }} color='error95' onClick={() => handleMerge()}>
                        {t('mergeAsPrivateTour')}
                    </Button>
                )}
                {!selectedDriver && (
                    <Button variant='contained' sx={{ height: '40px', marginBottom: '0px !important' }} color='error95' disabled>
                        {t('mergeAsPrivateTour')}
                    </Button>
                )}
            </DialogActions>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}
